import { Heading, Link, Stack, Text, useToast } from "@chakra-ui/react";
import MonthlyReportHeader from "../../components/MonthlyReportHeader";
import MonthlyReportNavButtons from "../../components/MonthlyReportNavButtons";
import useCurrentReport from "../../hooks/useCurrentReport";
import useFirestoreUser from "../../hooks/useFirestoreUser";
import useNavigateNext from "../../hooks/useNavigateNext";
import { useReportingForParam } from "../../hooks/useReportingForMonth";
import useReports from "../../hooks/useReports";
import useStages from "../../hooks/useStages";
import useStagesCount from "../../hooks/useStagesCount";
import useUpdateReportDoc from "../../hooks/useUpdateReportDoc";
import LiveSessions from "./LiveSessions";
import StagesTable from "./StagesTable";
import useStore from "../../store/useStore";
import {
  CollectionReference,
  collection,
  doc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import FirestoreUser, { StageStatus } from "../../types/FirestoreUser";
import EssentialsCourses from "./EssentialsCourses";
import usePurpleBubblesText from "../../hooks/usePurpleBubblesText";
import PurpleBubbleContent from "../../components/PurpleBubbleContent";

export default () => {
  const updateReport = useUpdateReportDoc();
  const navNext = useNavigateNext();
  const stages = useStages();
  const stagesCount = useStagesCount();
  const report = useCurrentReport();
  const reports = useReports();
  const reportingFor = useReportingForParam();
  const user = useFirestoreUser();
  const toast = useToast();
  const userStages = useStore((state) => state.firestoreUser?.stages || {});
  const uid = useStore((state) => state.user?.uid);
  const purpleBubbleContent = usePurpleBubblesText("Step-12");

  const update = async ({
    id,
    status,
  }: {
    id: string;
    status: StageStatus;
  }) => {
    if (!uid) return null;
    const coll = collection(
      getFirestore(),
      "users"
    ) as CollectionReference<FirestoreUser>;
    return Promise.all([
      updateDoc(doc(coll, uid), {
        stages: {
          ...userStages,
          [id]: status,
        },
      }),
      updateReport({
        stageProgress: {
          ...report?.stageProgress,
          [id]: status,
        },
      }),
    ]);
  };

  if (!user) return null;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!report?.sundays) {
          return toast({
            status: "info",
            title: "Incomplete",
            description:
              'Please fill out the Live Sessions section. If you did not attend or watch any sessions, check "None"',
          });
        }
        updateReport({ stages: true });
        navNext();
      }}
    >
      <Stack spacing={8}>
        <MonthlyReportHeader title={`Core Education & ${stagesCount} Steps`} />
        {purpleBubbleContent?.text && (
          <PurpleBubbleContent text={purpleBubbleContent.text} />
        )}
        <LiveSessions
          reports={reports}
          month={reportingFor}
          update={updateReport}
        />
        <EssentialsCourses editable={true} user={user} />

        <Stack spacing={4}>
          <Heading fontSize="md">47 Steps</Heading>
          <Text>
            Did you complete or start any of the
            {stages ? " " + (stages.length - 1) : ""} available Steps from
            HealingALS.org this month?
            {stages && stages.length < stagesCount + 1
              ? " More steps are coming soon!"
              : ""}
          </Text>
          <Link
            href="https://healingals.org/47-steps/"
            isExternal
            color="purple.600"
          >
            Visit 47 Steps to Healing
          </Link>
        </Stack>
        <StagesTable update={update} user={user} />
        <MonthlyReportNavButtons />
      </Stack>
    </form>
  );
};
