import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Heading,
  SimpleGrid,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  addNewPanelsToUserLab,
  useLabs,
  useUserLabs,
} from "../../data/labsCollection";
import CenteredSpinner from "../../components/CenteredSpinner";
import useStore from "../../store/useStore";
import { QueryDocumentSnapshot } from "firebase/firestore";
import { Lab, UserLab, UserPanel } from "../../types/bloodWork";
import React from "react";

type UserLabWithId = UserLab & {
  labId: string;
};

export default () => {
  const userId = useStore((s) => s.user?.uid);
  const [labs, foundLabs] = useLabs({ isArchived: false });
  const [userLabs] = useUserLabs({
    userId: userId,
    isArchived: false,
  });

  const conventionalLabTemplate = labs
    ?.filter((lab) => lab.data().title === "Conventional")
    .map((lab) => {
      return lab.data();
    })[0];

  const functionalLabTemplate = labs
    ?.filter((lab) => lab.data().title === "Functional")
    .map((lab) => {
      return lab.data();
    })[0];

  const conventionalLabs = userLabs
    ?.filter((userLab) => userLab.data().title === "Conventional")
    .map((userLab) => {
      if (conventionalLabTemplate) {
        return {
          labId: userLab.id,
          ...addNewPanelsToUserLab(conventionalLabTemplate, userLab.data()),
        };
      }
      return { labId: userLab.id, ...userLab.data() };
    });

  const functionalLabs = userLabs
    ?.filter((userLab) => userLab.data().title === "Functional")
    .map((userLab) => {
      if (functionalLabTemplate) {
        return {
          labId: userLab.id,
          ...addNewPanelsToUserLab(functionalLabTemplate, userLab.data()),
        };
      }
      return { labId: userLab.id, ...userLab.data() };
    });

  return (
    <Stack spacing={5}>
      <Heading>Your Lab Test Records</Heading>
      {foundLabs !== true && <CenteredSpinner />}
      {foundLabs && (
        <Tabs>
          <TabList>
            <Tab>Add/Update Labs</Tab>
            <Tab>Conventional Lab History</Tab>
            <Tab>Functional Lab History</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <AddUpdateLabs labs={labs} userLabs={userLabs} />
              <Link to={"/labs/archived"}>
                <Button colorScheme="orange" rightIcon={<DeleteIcon />}>
                  Archives
                </Button>
              </Link>
            </TabPanel>
            <TabPanel>
              <Heading size={"lg"} mt="5">
                Conventional Labs
              </Heading>
              <LabHistory userLabs={conventionalLabs} />
            </TabPanel>
            <TabPanel>
              <Heading size={"lg"} mt="5">
                Functional Labs
              </Heading>
              <LabHistory userLabs={functionalLabs} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Stack>
  );
};

const AddUpdateLabs = ({
  labs,
  userLabs,
}: {
  labs: QueryDocumentSnapshot<Lab>[] | undefined;
  userLabs: QueryDocumentSnapshot<UserLab>[] | undefined;
}) => {
  return (
    <SimpleGrid columns={2} columnGap={5}>
      {labs &&
        labs.map((lab, labIndex) => {
          return (
            <Box key={labIndex}>
              <Link to={`/labs/add/${lab.data().title}`}>
                <Button rightIcon={<AddIcon />}>
                  Add {lab.data().title} Lab Test
                </Button>
              </Link>
              <Heading size={"md"} mt="5">
                {lab.data().title} Lab Tests
              </Heading>
              <Divider />
              <Box mt="5">
                {userLabs &&
                  userLabs
                    .filter(
                      (userLab) => userLab.data().title === lab.data().title
                    )
                    .map((userLab, userLabIndex) => {
                      return (
                        <Card key={userLabIndex} mb="5">
                          <Link to={`/labs/edit/${userLab.id}`}>
                            <CardBody>
                              <p>
                                <EditIcon boxSize={6} mr="10" />
                                {
                                  userLab
                                    .data()
                                    .date.toDate()
                                    .toISOString()
                                    .split("T")[0]
                                }
                              </p>
                            </CardBody>
                          </Link>
                        </Card>
                      );
                    })}
              </Box>
            </Box>
          );
        })}
    </SimpleGrid>
  );
};

const LabHistory = ({
  userLabs,
}: {
  userLabs: UserLabWithId[] | undefined;
}) => {
  const years = userLabs?.map((userLabAndId, index) => {
    return (
      <Th key={index}>
        {userLabAndId.date.toDate().toISOString().split("T")[0]}
      </Th>
    );
  });

  return (
    <>
      {userLabs
        ?.entries()
        .next()
        .value?.[1].panels?.map((panel: UserPanel, panelIndex: number) => {
          return (
            <React.Fragment key={panelIndex}>
              <Heading mt="5" size="md">
                {panel.title}
              </Heading>
              <Divider mb="5" />
              <TableContainer>
                <Table size="sm" variant="unstyled">
                  <Thead>
                    <Tr>
                      <Th>Test</Th>
                      {years}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {panel.items?.map((item, itemIndex) => {
                      return (
                        <Tr key={`${panelIndex}-${itemIndex}`}>
                          <Td>{item.title}</Td>
                          {userLabs.map((userLab, colIndex) => {
                            return (
                              <Td key={colIndex}>
                                {userLab.panels[panelIndex].items[itemIndex]
                                  ? userLab.panels[panelIndex].items[itemIndex]
                                      .result
                                  : ""}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </React.Fragment>
          );
        })}
    </>
  );
};
