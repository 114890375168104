import {
  Table,
  Thead,
  Text,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Button,
  useToast,
} from '@chakra-ui/react'
import FirestoreUser from '../../types/FirestoreUser'
import usePurpleBubblesText from '../../hooks/usePurpleBubblesText'
import PurpleBubbleContent from '../../components/PurpleBubbleContent'
import {
  moldConditions,
  heavyMetalConditions,
  petroleumConditions,
  pesticideConditions,
  chemicalConditions,
  plasticConditions,
  militaryConditions,
  geopathicConditions,
  emfConditions,
  radiationConditions,
  carbonMonoxideConditions,
} from './exposures'
import ExposureRow from './ExposureRow'
import { ExposureData } from '../../types/FirestoreUser'
import { useMemo, useState, useEffect } from 'react'
import { getAuth } from 'firebase/auth'
import { doc, getFirestore, getDoc, updateDoc } from 'firebase/firestore'
import { calculateAge } from '../../utils/calculateAge'

const SaveButton = ({
  isSaving,
  onClick,
  exposureData,
  fieldName,
}: {
  isSaving: boolean
  onClick: (exposureData: ExposureData[], fieldName: string) => Promise<void>
  exposureData: ExposureData[]
  fieldName: string
}) => (
  <Button
    size="lg"
    isLoading={isSaving}
    onClick={() => onClick(exposureData, fieldName)}
  >
    Save
  </Button>
)

interface ConditionSectionProps {
  title: string
  conditions: { name: string }[]
  uid: string
  exposureHistory: ExposureData[]
  setExposureHistory: (data: ExposureData[]) => void
}

function ConditionSection({
  title,
  conditions,
  uid,
  exposureHistory,
  setExposureHistory,
  userAge,
}: ConditionSectionProps & { userAge: number | undefined }) {
  return (
    <>
      <Tr>
        <Td colSpan={6}>
          <Text fontWeight="bold">{title}</Text>
        </Td>
      </Tr>
      {conditions
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((condition) => (
          <ExposureRow
            key={condition.name}
            condition={condition.name}
            uid={uid}
            exposureHistory={exposureHistory}
            setExposureHistory={setExposureHistory}
            userAge={userAge}
          />
        ))}
    </>
  )
}

interface Props {
  uid: string
  firestoreUser: FirestoreUser
}

export default function ProfileEnvironmental({ uid, firestoreUser }: Props) {
  const [exposureHistory, setExposureHistory] = useState<ExposureData[]>([])
  const [initialExposureHistory, setInitialExposureHistory] = useState<
    ExposureData[]
  >([])
  const [isSaving, setIsSaving] = useState(false)

  const purpleBubbleContent = usePurpleBubblesText('Profile-step-4')
  const toast = useToast()

  const userAge = useMemo(() => {
    const birthDate = firestoreUser.profile?.birthDate
    if (!birthDate) return undefined
    return calculateAge(birthDate)
  }, [firestoreUser])

  useEffect(() => {
    async function fetchExposureHistory() {
      const user = getAuth().currentUser
      if (!user) {
        alert('Not logged in!')
        return
      }
      const userRef = doc(getFirestore(), 'users', user.uid)
      const userDoc = await getDoc(userRef)
      if (userDoc.exists()) {
        const data = userDoc.data()
        const exposureData = data.medicalDetails?.exposureHistory || []
        setExposureHistory(exposureData)
        setInitialExposureHistory(exposureData)
      }
    }

    fetchExposureHistory()
  }, [])

  async function saveExposureHistory(
    exposureData: ExposureData[],
    fieldName: string
  ) {
    setIsSaving(true)
    const invalidEntries = exposureData.filter(
      (entry) =>
        entry.ageDiagnosed !== undefined &&
        entry.ageDiagnosed !== null &&
        entry.ageEnded !== null &&
        entry.ageEnded < entry.ageDiagnosed
    )

    if (invalidEntries.length > 0) {
      const invalidConditions = invalidEntries.map((entry) => entry.condition)
      toast({
        title: 'Error',
        description: `The following Known Toxic Exposures have incorrect values: ${invalidConditions.join(
          ', '
        )}. Age at end of exposure cannot be less than age at start of exposure.`,
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
      setIsSaving(false)
      return
    }

    const user = getAuth().currentUser
    if (!user) {
      alert('Not logged in!')
      setIsSaving(false)
      return
    }
    const userRef = doc(getFirestore(), 'users', user.uid)
    await updateDoc(userRef, {
      [`medicalDetails.${fieldName}`]: exposureData,
    })
    setIsSaving(false)
    toast({
      title: 'Success',
      description: `Your environmental exposure data has been successfully saved.`,
      status: 'success',
      duration: 5000,
      isClosable: true,
    })
  }

  return (
    <>
      <form>
        {purpleBubbleContent?.text && (
          <PurpleBubbleContent text={purpleBubbleContent.text} />
        )}
        <Box mb={4} mt={4}>
          <SaveButton
            isSaving={isSaving}
            onClick={saveExposureHistory}
            exposureData={exposureHistory}
            fieldName="exposureHistory"
          />
        </Box>
        <Table variant="simple" mt={4}>
          <Thead>
            <Tr>
              <Th textAlign="left">Known Toxic Exposures</Th>
              <Th textAlign="left">Have/had exposure</Th>
              <Th textAlign="left">Age at start of exposure</Th>
              <Th textAlign="left">
                Age at end of exposure (Blank if current)
              </Th>
              <Th textAlign="left">Estimated total years</Th>
            </Tr>
          </Thead>
          <Tbody>
            <ConditionSection
              title="Mold and Mycotoxins in home or work"
              conditions={moldConditions}
              uid={uid}
              exposureHistory={exposureHistory}
              setExposureHistory={setExposureHistory}
              userAge={userAge}
            />
            <ConditionSection
              title="Heavy Metals"
              conditions={heavyMetalConditions}
              uid={uid}
              exposureHistory={exposureHistory}
              setExposureHistory={setExposureHistory}
              userAge={userAge}
            />
            <ConditionSection
              title="Petroleum/Gasoline/Diesel"
              conditions={petroleumConditions}
              uid={uid}
              exposureHistory={exposureHistory}
              setExposureHistory={setExposureHistory}
              userAge={userAge}
            />
            <ConditionSection
              title="Pesticides"
              conditions={pesticideConditions}
              uid={uid}
              exposureHistory={exposureHistory}
              setExposureHistory={setExposureHistory}
              userAge={userAge}
            />
            <ConditionSection
              title="Chemicals, Solvents and more"
              conditions={chemicalConditions}
              uid={uid}
              exposureHistory={exposureHistory}
              setExposureHistory={setExposureHistory}
              userAge={userAge}
            />
            <ConditionSection
              title="Plastics"
              conditions={plasticConditions}
              uid={uid}
              exposureHistory={exposureHistory}
              setExposureHistory={setExposureHistory}
              userAge={userAge}
            />
            <ConditionSection
              title="Military Exposure"
              conditions={militaryConditions}
              uid={uid}
              exposureHistory={exposureHistory}
              setExposureHistory={setExposureHistory}
              userAge={userAge}
            />
            <ConditionSection
              title="Geopathic fields"
              conditions={geopathicConditions}
              uid={uid}
              exposureHistory={exposureHistory}
              setExposureHistory={setExposureHistory}
              userAge={userAge}
            />
            <ConditionSection
              title="Known EMF exposure"
              conditions={emfConditions}
              uid={uid}
              exposureHistory={exposureHistory}
              setExposureHistory={setExposureHistory}
              userAge={userAge}
            />
            <ConditionSection
              title="Radiation Exposure"
              conditions={radiationConditions}
              uid={uid}
              exposureHistory={exposureHistory}
              setExposureHistory={setExposureHistory}
              userAge={userAge}
            />
            <ConditionSection
              title="Carbon Monoxide"
              conditions={carbonMonoxideConditions}
              uid={uid}
              exposureHistory={exposureHistory}
              setExposureHistory={setExposureHistory}
              userAge={userAge}
            />
          </Tbody>
        </Table>
        <Box mb={4} mt={4}>
          <SaveButton
            isSaving={isSaving}
            onClick={saveExposureHistory}
            exposureData={exposureHistory}
            fieldName="exposureHistory"
          />
        </Box>
      </form>
    </>
  )
}
