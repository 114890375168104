import { Heading, Stack, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addNewPanelsToUserLab,
  useLabs,
  userLabsStore,
  useUserLab,
} from "../../data/labsCollection";
import CenteredSpinner from "../../components/CenteredSpinner";
import NotFound from "../NotFound";
import useStore from "../../store/useStore";
import { UserLab } from "../../types/bloodWork";
import UserLabTable from "../../components/BloodWork/UserLabTable";
import ScrollToTop from "../../components/ScrollToTop";

export default () => {
  const { userLabId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const userId = useStore((s) => s.user?.uid);

  const [userLabSnapshot, labFound] = useUserLab({
    userLabId: userLabId ?? "",
    userId: userId,
  });

  let userLab = userLabSnapshot?.data();

  const [labSnapshot] = useLabs({
    title: userLab?.title ?? "",
    isArchived: false,
  });

  let lab = null;

  if (labSnapshot && labSnapshot.length === 1) {
    lab = labSnapshot[0].data();
  }

  if (lab && userLab) {
    userLab = addNewPanelsToUserLab(lab, userLab);
  }

  let archiveFunc = undefined;
  let submitButtonText = "Restore";
  let submitButtonColorScheme = "whatsapp";
  let readOnly = true;
  let onSubmit = async (userLab: UserLab) => {
    await userLabsStore.unarchive(userLabId ?? "");
    toast({ status: "success", description: "Lab Restored!" });
  };
  let cancelFunc = () => {
    navigate("/labs/archived");
  };

  if (!userLab?.archived) {
    submitButtonText = "Update";
    submitButtonColorScheme = "purple";
    readOnly = false;
    onSubmit = async (userLab: UserLab) => {
      await userLabsStore.update(userLabId ?? "", userLab);
      toast({ status: "success", description: "Lab updated!" });
    };

    cancelFunc = () => {
      navigate("/labs");
    };

    archiveFunc = async () => {
      if (window.confirm("Archive this lab? You can restore it at anytime.")) {
        //setting the navigation first
        //when setting after archive, the userLab becomes "<NotFound />"
        //before the navigation takes place
        navigate("/labs");
        await userLabsStore.archive(userLabId ?? "");
      }
    };
  }

  if (labFound === false) {
    return <NotFound />;
  }

  if (!userLab) {
    return <CenteredSpinner />;
  }

  return (
    userLab && (
      <Stack spacing={5}>
        <ScrollToTop />
        <Heading>Edit {userLab.title} Lab Test</Heading>
        <UserLabTable
          userLab={userLab}
          onSubmitFunc={onSubmit}
          submitButtonText={submitButtonText}
          submitButtonColorScheme={submitButtonColorScheme}
          cancelFunc={cancelFunc}
          archiveFunc={archiveFunc}
          readOnly={readOnly}
        />
      </Stack>
    )
  );
};
