import React, { useState, CSSProperties } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Text,
  Divider,
  Box,
} from '@chakra-ui/react'
import PurpleBubbleContent from '../../../components/PurpleBubbleContent'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import parse from 'html-react-parser'

interface ModalComponentProps {
  isOpen: boolean
  onClose: () => void
  status: 'adding' | 'editing' | ''
  title: string
  text: string
  setTitle: (title: string) => void
  setText: (text: string) => void
  handleSave: () => void
  collectionName: string
}

const ModalComponent: React.FC<ModalComponentProps> = ({
  isOpen,
  onClose,
  status,
  title,
  text,
  setTitle,
  setText,
  handleSave,
  collectionName,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSaveClick = async () => {
    setIsLoading(true)
    await handleSave()
    setIsLoading(false)
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      [{ align: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: [] }],
      ['link'],
      ['clean'],
    ],
  }

  const editorStyle: CSSProperties = {
    maxHeight: '300px',
    overflowY: 'auto',
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {status === 'adding'
            ? `Add new content for ${collectionName}`
            : `Edit content for ${title}`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {status === 'adding' && (
            <Input
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              mb={4}
            />
          )}
          <Box style={editorStyle} className="sticky-toolbar">
            <ReactQuill value={text} onChange={setText} modules={modules} />
          </Box>
          <Text mt={4} fontWeight="bold" fontSize="lg">
            Preview
          </Text>
          <Divider my={4} />
          <Box style={editorStyle}>
            {collectionName === 'Purple Bubbles' ? (
              <PurpleBubbleContent text={text} />
            ) : (
              // Wrapping the parsed HTML in Quill's CSS containers lets Quill's styles take effect.
              <Box className="ql-container ql-snow">
                <Box className="ql-editor">{parse(text)}</Box>
              </Box>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSaveClick}
            isLoading={isLoading}
          >
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
      <style>{`
        .sticky-toolbar .ql-toolbar {
          position: sticky;
          top: 0;
          z-index: 1;
          background: white;
        }
      `}</style>
    </Modal>
  )
}

export default ModalComponent
