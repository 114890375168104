import useStore from "../../store/useStore";
import FirestoreUser from "../../types/FirestoreUser";
import Courses from "./Courses";

const EssentialsCourses = ({
  editable,
  user,
}: {
  editable: boolean;
  user: FirestoreUser;
}) => {
  const numEssentialsCourses = useStore(
    (s) => s.meta?.numEssentialsCourses || 0
  );
  const essentialsCoursesNames = useStore(
    (s) => s.meta?.essentialsCoursesNames || []
  );

  return (
    <Courses
      editable={editable}
      user={user}
      courseType="essentialsCourses"
      courseNames={essentialsCoursesNames}
      numCourses={numEssentialsCourses}
      displayName="Essentials Courses"
      hyperlink={{
        link: "https://healingals.org/7-day-quick-start/",
        text: "Visit 7 Day Quick Start Essentials to Healing",
      }}
    />
  );
};

export default EssentialsCourses;
