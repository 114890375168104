import { QuestionIcon } from "@chakra-ui/icons";
import {
  Center,
  Divider,
  FormControl,
  Heading,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Tr,
} from "@chakra-ui/react";
import { Range, UserLab, UserPanel } from "../../types/bloodWork";
import { useForm } from "react-hook-form";
import { Timestamp } from "firebase/firestore";
import BasicDatePicker from "../../components/BasicDatePicker";
import { Fragment, useState, useEffect, useCallback } from "react";
import BloodWorkActionButtons from "./BloodWorkActionButtons";
import { calculatedFields } from "./LabTestCalculatedFields";
import useConfigList from "../../hooks/useConfigList";

export default ({
  userLab,
  onSubmitFunc,
  submitButtonText,
  submitButtonColorScheme,
  cancelFunc,
  archiveFunc,
  readOnly,
}: {
  userLab: UserLab;
  onSubmitFunc?: (data: UserLab) => Promise<void> | undefined;
  submitButtonText?: string;
  submitButtonColorScheme?: string;
  cancelFunc?: () => void | undefined;
  archiveFunc?: () => Promise<void> | undefined;
  readOnly?: boolean;
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formReadOnly, setFormReadOnly] = useState(readOnly);
  const { register, handleSubmit, getValues, setValue, watch } =
    useForm<UserLab>();
  const bloodWorkUnits = useConfigList("measurement-units");

  const wrappedSubmitFunc = onSubmitFunc
    ? async (data: UserLab) => {
        if (data) {
          data.date = Timestamp.fromDate(
            new Date(`${year}-${month}-${day}T00:00:00`)
          );
          setButtonDisabled(true);
          setButtonLoading(true);
          setFormReadOnly(true);
          onSubmitFunc(data)?.then(() => {
            setButtonDisabled(false);
            setButtonLoading(false);
            setFormReadOnly(false);
          });
        }
      }
    : undefined;

  const onSubmit = onSubmitFunc
    ? handleSubmit(async (data) => {
        if (wrappedSubmitFunc) {
          wrappedSubmitFunc(data);
        }
      })
    : undefined;

  const wrappedArchiveFunc = archiveFunc
    ? async () => {
        setButtonDisabled(true);
        setButtonLoading(true);
        archiveFunc()?.then(() => {
          setButtonDisabled(false);
          setButtonLoading(false);
        });
      }
    : undefined;

  const [year, setYear] = useState(
    userLab.date.toDate().getFullYear().toString()
  );
  const monthNum = userLab.date.toDate().getMonth() + 1;
  const [month, setMonth] = useState(
    monthNum > 9 ? monthNum.toString() : "0" + monthNum
  );
  const dayNum = userLab.date.toDate().getDate();
  const [day, setDay] = useState(dayNum > 9 ? dayNum.toString() : "0" + dayNum);

  //Watch all fields
  watch();

  const updateCalculatedField = useCallback(
    (formData: UserLab, panelIndex: number, dependancyTitle: string) => {
      calculatedFields.forEach((field) => {
        if (
          field.dividend === dependancyTitle ||
          field.divisor === dependancyTitle
        ) {
          const value = calculateRatio(
            formData.panels[panelIndex],
            field.dividend,
            field.divisor
          );

          const pathType = "userId"; //Hack to get the type
          const calculatedFieldPath = getResultPathForTitle(
            formData,
            panelIndex,
            field.title
          ) as typeof pathType;

          setValue(calculatedFieldPath, value);
        }
      });
    },
    [setValue]
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (!name || !type) {
        return;
      }
      // console.log(value, name, type, ". value:", getValues(name));
      const panelIndex = parseInt(name?.toString().split(".")[1]);
      const titlePath = name
        ?.toString()
        .replace("result", "title") as typeof name;
      const title = getValues(titlePath) as string;

      updateCalculatedField(value as UserLab, panelIndex, title);
    });
    return () => subscription.unsubscribe();
  }, [getValues, watch, updateCalculatedField]);

  return (
    <Stack spacing={5}>
      {userLab && (
        <FormControl>
          <form onSubmit={onSubmit}>
            <BloodWorkActionButtons
              onSubmitFunc={onSubmitFunc}
              submitButtonText={submitButtonText}
              submitButtonColorScheme={submitButtonColorScheme}
              isLoading={buttonLoading}
              isDisabled={buttonDisabled}
              cancelFunc={cancelFunc}
              archiveFunc={wrappedArchiveFunc}
            />
            <Input
              type="hidden"
              {...register("title", { value: userLab?.title })}
            />
            <Stack mt="5">
              <TableContainer>
                <Table size="sm" variant="unstyled">
                  <Tbody>
                    <Tr>
                      <Th>
                        <Heading size="md">Lab Date</Heading>
                        <Divider mb="5" />
                        <BasicDatePicker
                          startYear={"1920"}
                          endYear={new Date().getFullYear().toString()}
                          year={year}
                          setYear={setYear}
                          month={month}
                          setMonth={setMonth}
                          day={day}
                          setDay={setDay}
                        />
                      </Th>
                    </Tr>
                    {userLab?.panels?.map((panel, panelIndex) => {
                      return (
                        <Fragment key={panelIndex}>
                          <Tr>
                            <Th>
                              <Heading mt="5" size="md">
                                {panel.title}
                              </Heading>
                              <Divider />
                              <Input
                                type="hidden"
                                {...register(`panels.${panelIndex}.title`, {
                                  value: panel.title,
                                })}
                              />
                            </Th>
                          </Tr>
                          <Tr>
                            <Th>Test</Th>
                            <Th>Result</Th>
                            <Th>Reference Range</Th>
                            <Th>
                              Optimal Range{" "}
                              <Popover>
                                <PopoverTrigger>
                                  <QuestionIcon color="gray.400" />
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverArrow />
                                  <PopoverCloseButton />
                                  <PopoverHeader
                                    fontWeight="normal"
                                    textTransform="none"
                                    whiteSpace="normal"
                                    fontSize="md"
                                  >
                                    Optimal Range
                                  </PopoverHeader>
                                  <PopoverBody
                                    fontWeight="normal"
                                    textTransform="none"
                                    whiteSpace="normal"
                                    fontSize="md"
                                  >
                                    Optimal range will only appear if it is
                                    different from the reference range.
                                  </PopoverBody>
                                </PopoverContent>
                              </Popover>
                            </Th>
                          </Tr>
                          {panel.items?.map(
                            (
                              {
                                referenceRange,
                                title,
                                optimalRange,
                                unit,
                                result,
                              },
                              itemIndex
                            ) => {
                              const formatRange = (range: Range) => {
                                return `${range.bottom} - ${range.top} ${bloodWorkUnits[unit]}`;
                              };

                              //Calculated fields display as text instead of input
                              let fieldType = "";
                              calculatedFields.forEach((field) => {
                                if (field.title === title) {
                                  fieldType = "hidden";
                                }
                              });

                              let inputField = (
                                <Input
                                  id={`${panelIndex}-${itemIndex}`}
                                  size="sm"
                                  type={fieldType}
                                  readOnly={formReadOnly}
                                  placeholder="Result"
                                  maxW="80px"
                                  {...register(
                                    `panels.${panelIndex}.items.${itemIndex}.result`,
                                    {
                                      value: result,
                                    }
                                  )}
                                />
                              );

                              if (
                                referenceRange.bottom
                                  .toString()
                                  .toLocaleLowerCase() === "neg"
                              ) {
                                inputField = (
                                  <select
                                    {...register(
                                      `panels.${panelIndex}.items.${itemIndex}.result`
                                    )}
                                  >
                                    <option value="">Select...</option>
                                    <option value="Neg">Neg</option>
                                    <option value="Pos">Pos</option>
                                  </select>
                                );
                              }

                              return (
                                <Tr key={itemIndex}>
                                  <Td>{title}</Td>
                                  <Td>
                                    {fieldType === "hidden" && (
                                      <Center>
                                        {getValues(
                                          `panels.${panelIndex}.items.${itemIndex}.result`
                                        ) ?? result}{" "}
                                        %
                                      </Center>
                                    )}
                                    {inputField}
                                    <Input
                                      type="hidden"
                                      {...register(
                                        `panels.${panelIndex}.items.${itemIndex}.title`,
                                        {
                                          value: title,
                                        }
                                      )}
                                    />
                                    <Input
                                      type="hidden"
                                      {...register(
                                        `panels.${panelIndex}.items.${itemIndex}.unit`,
                                        {
                                          value: unit,
                                        }
                                      )}
                                    />
                                    <Input
                                      type="hidden"
                                      {...register(
                                        `panels.${panelIndex}.items.${itemIndex}.referenceRange.top`,
                                        {
                                          value: referenceRange.top,
                                        }
                                      )}
                                    />
                                    <Input
                                      type="hidden"
                                      {...register(
                                        `panels.${panelIndex}.items.${itemIndex}.referenceRange.bottom`,
                                        {
                                          value: referenceRange.bottom,
                                        }
                                      )}
                                    />
                                    {optimalRange && (
                                      <>
                                        <Input
                                          type="hidden"
                                          {...register(
                                            `panels.${panelIndex}.items.${itemIndex}.optimalRange.top`,
                                            {
                                              value: optimalRange.top,
                                            }
                                          )}
                                        />
                                        <Input
                                          type="hidden"
                                          {...register(
                                            `panels.${panelIndex}.items.${itemIndex}.optimalRange.bottom`,
                                            {
                                              value: optimalRange.bottom,
                                            }
                                          )}
                                        />
                                      </>
                                    )}
                                  </Td>
                                  <Td>{formatRange(referenceRange)}</Td>
                                  <Td>
                                    {optimalRange
                                      ? formatRange(optimalRange)
                                      : ""}
                                  </Td>
                                </Tr>
                              );
                            }
                          )}
                        </Fragment>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>

            <BloodWorkActionButtons
              onSubmitFunc={onSubmitFunc}
              submitButtonText={submitButtonText}
              submitButtonColorScheme={submitButtonColorScheme}
              isLoading={buttonLoading}
              isDisabled={buttonDisabled}
              cancelFunc={cancelFunc}
              archiveFunc={wrappedArchiveFunc}
            />
          </form>
        </FormControl>
      )}
    </Stack>
  );
};

const getResultPathForTitle = (
  formData: UserLab,
  panelIndex: number,
  title: string
): string => {
  let itemIndex = 0;
  formData.panels[panelIndex].items.forEach((item, idx) => {
    if (item.title === title) {
      itemIndex = idx;
    }
  });
  return `panels.${panelIndex}.items.${itemIndex}.result`;
};

const calculateRatio = (
  panel: UserPanel,
  dividendTitle: string,
  divisorTitle: string
): string => {
  let dividend = 0;
  let divisor = 0;

  panel.items?.forEach((item) => {
    if (item.title === dividendTitle) {
      dividend = parseFloat(item.result + "");
    }
    if (item.title === divisorTitle) {
      divisor = parseFloat(item.result + "");
    }
  });

  if (isNaN(dividend) || isNaN(divisor) || divisor === 0) {
    return "";
  }

  return (dividend / divisor).toFixed(2);
};
