import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  CollectionReference,
  QueryDocumentSnapshot,
  QuerySnapshot,
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { chain, orderBy } from "lodash";
import { useState } from "react";
import useDoctors from "../hooks/useDoctors";
import useHandleError from "../hooks/useHandleError";
import Doctor from "../types/Doctor";

export default () => {
  const modal = useDisclosure();
  const doctorsCollection = collection(
    getFirestore(),
    "doctors"
  ) as CollectionReference<Doctor>;
  const doctors = useDoctors();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [letters, setLetters] = useState<string[]>([]);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [editingId, setEditingId] = useState<string>();
  const [archived, setArchived] = useState(false);
  const handleError = useHandleError();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const closeModal = () => {
    modal.onClose();
    setFirstName("");
    setLastName("");
    setLetters([]);
    setCity("");
    setState("");
    setCountry("");
    setEditingId(undefined);
    setArchived(false);
  };

  return (
    <Stack spacing={6}>
      <Heading>Doctors</Heading>
      <Box>
        <Button onClick={modal.onOpen}>Add Doctor</Button>
      </Box>
      <Doctors
        doctors={doctors}
        onEdit={(doc) => {
          const doctor = doc.data();
          setFirstName(doctor.firstName);
          setLastName(doctor.lastName);
          setLetters(doctor.letters);
          setCity(doctor.city);
          setState(doctor.state);
          setCountry(doctor.country);
          setEditingId(doc.id);
          setArchived(doctor.archived);
          modal.onOpen();
        }}
      />
      <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editingId ? "Edit" : "Add"} Doctor</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  autoComplete="given-name"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  autoComplete="family-name"
                />
              </FormControl>
              <FormControl>
                <FormLabel>City</FormLabel>
                <Input
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  autoComplete="address-level2"
                />
              </FormControl>
              <FormControl>
                <FormLabel>State/Province</FormLabel>
                <Input
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  autoComplete="address-level1"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Country</FormLabel>
                <Input
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  autoComplete="country"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Letters</FormLabel>
                <SimpleGrid columns={3}>
                  {allLetters.map((letter) => {
                    const selected = letters.includes(letter);
                    return (
                      <Checkbox
                        key={letter}
                        isChecked={selected}
                        onChange={(e) => {
                          setLetters(
                            selected
                              ? letters.filter((l) => l !== letter)
                              : [...letters, letter]
                          );
                        }}
                      >
                        {letter}
                      </Checkbox>
                    );
                  })}
                </SimpleGrid>
              </FormControl>
              {editingId && (
                <FormControl>
                  <Checkbox
                    isChecked={archived}
                    onChange={(e) => setArchived(e.target.checked)}
                  >
                    Archived
                  </Checkbox>
                </FormControl>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" colorScheme="gray" onClick={closeModal} mr={3}>
              Cancel
            </Button>
            <Button
              isDisabled={loading}
              isLoading={loading}
              size="sm"
              onClick={async () => {
                if (!city || !country || !firstName || !lastName || !state) {
                  return toast({
                    status: "warning",
                    title: "Warning",
                    description: "Please fill out all fields before saving.",
                  });
                }
                if (letters.length === 0) {
                  return toast({
                    status: "warning",
                    title: "Warning",
                    description:
                      "Please select at least one letters before saving.",
                  });
                }
                const doctor = {
                  city,
                  country,
                  firstName,
                  lastName,
                  letters,
                  state,
                  archived,
                };
                setLoading(true);
                try {
                  if (editingId) {
                    await setDoc(doc(doctorsCollection, editingId), doctor);
                  } else {
                    doctors?.docs.forEach((doc) => {
                      if (
                        doc.data().firstName.toLocaleLowerCase() ===
                          firstName.toLocaleLowerCase() &&
                        doc.data().lastName.toLocaleLowerCase() ===
                          lastName.toLocaleLowerCase() &&
                        doc.data().state.toLocaleLowerCase() ===
                          state.toLocaleLowerCase()
                      ) {
                        throw new Error("Doctor already exists!");
                      }
                    });
                    await addDoc(doctorsCollection, doctor);
                  }
                  closeModal();
                } catch (e) {
                  handleError(e);
                } finally {
                  setLoading(false);
                }
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const allLetters = orderBy([
  "MD",
  "PhD",
  "DO",
  "DC",
  "ND",
  "N",
  "PA",
  "CNP",
  "NUTR",
  "HC",
  "PharmD",
]);

const Doctors = ({
  doctors,
  onEdit,
}: {
  doctors: QuerySnapshot<Doctor> | undefined;
  onEdit: (doc: QueryDocumentSnapshot<Doctor>) => void;
}) => {
  return (
    <TableContainer>
      <Table size="sm" variant="simple">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Name</Th>
            <Th>Letters</Th>
            <Th>City</Th>
            <Th>State/Province</Th>
            <Th>Country</Th>
            <Th>Archived?</Th>
          </Tr>
        </Thead>
        <Tbody>
          {chain(doctors?.docs || [])
            .orderBy((doc) => {
              const doctor = doc.data();
              return `${doctor.lastName}, ${doctor.firstName}`;
            }, "asc")
            .map((doc) => {
              const doctor = doc.data();
              let archivedColor = { color: doctor.archived ? "lightgrey" : "" };
              return (
                <Tr key={doc.id} style={archivedColor}>
                  <Td>
                    <Button
                      size="xs"
                      variant="ghost"
                      onClick={() => onEdit(doc)}
                    >
                      Edit
                    </Button>
                  </Td>
                  <Td>
                    {doctor.lastName}, {doctor.firstName}
                  </Td>
                  <Td>{doctor.letters.join("/")}</Td>
                  <Td>{doctor.city}</Td>
                  <Td>{doctor.state}</Td>
                  <Td>{doctor.country}</Td>
                  <Td>{doctor.archived ? "Yes" : "No"}</Td>
                </Tr>
              );
            })
            .value()}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
