import {
  CollectionReference,
  Timestamp,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { MonthlyReport, Reports } from '../store/useStore'
import FirestoreUser from '../types/FirestoreUser'
import { Regimen } from '../types/Regimen'
import ErrorPage from './ErrorPage'
import { PrintContent } from './Print'

export default () => {
  const { uid } = useParams<{ uid: string }>()

  const { data } = useQuery(
    `adminPrint/${uid}`,
    async () => {
      const db = getFirestore()
      const [{ data: user }, regimensSnap, reportsSnap] = await Promise.all([
        httpsCallable<any, FirestoreUser>(getFunctions(), 'getUser')({ uid }),
        getDocs(
          query(
            collection(db, 'regimens') as CollectionReference<
              Omit<Regimen, 'id'>
            >,
            where('uid', '==', uid)
          )
        ),
        getDocs(
          query(
            collection(
              db,
              `users/${uid}/reports`
            ) as CollectionReference<MonthlyReport>
          )
        ),
      ])

      const reports: Reports = {}
      reportsSnap.forEach((doc) => {
        reports[doc.id] = doc.data()
      })

      const regimens: Regimen[] = regimensSnap.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        }
      })

      return {
        user,
        reports,
        regimens,
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  )

  if (!data || !uid) return null

  const { regimens, reports, user } = data

  if (!user) return <ErrorPage subtitle="User data not found." />

  return (
    <PrintContent
      {...data}
      regimens={regimens}
      reports={reports}
      user={user}
      uid={uid}
    />
  )
}
