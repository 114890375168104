import { AddIcon, CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { addDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { chain } from "lodash";
import { Fragment, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useParams } from "react-router-dom";
import labsCollection, { useLabs } from "../data/labsCollection";
import useHandleError from "../hooks/useHandleError";
import { Lab, Panel, PanelItem } from "../types/bloodWork";
import randomId from "../utils/randomId";
import NotFound from "./NotFound";
import AdminBloodWork from "../pages/AdminBloodWork";
import useConfigList from "../hooks/useConfigList";

type LabInput = {
  title: string;
  panels: {
    id: string;
    title: string;
    items: PanelItemInput[];
    archived?: boolean;
  }[];
  archived?: boolean;
};

type PanelItemInput = {
  id: string;
  title: string;
  unit: string;
  referenceRange: {
    bottom: string;
    top: string;
  };
  optimalRange: {
    bottom: string;
    top: string;
  };
  archived?: boolean;
};

export default () => {
  const { editingId } = useParams<{ editingId: string }>();
  const [lab, setLab] = useState<LabInput>({ title: "", panels: [] });
  const [copyLabId, setCopyLabId] = useState("");
  const [saving, setSaving] = useState(false);
  const [labs] = useLabs({});
  const handleError = useHandleError();
  const toast = useToast();
  const [notFound, setNotFound] = useState(false);
  const bloodWorkUnits = useConfigList("measurement-units");

  const setLabFromCopy = (copyLab: Lab) => {
    setLab({
      title: copyLab.title,
      archived: copyLab.archived,
      panels: copyLab.panels.map((panel) => {
        return {
          id: panel.id,
          title: panel.title,
          archived: panel.archived,
          items: panel.items.map((item) => {
            return {
              id: item.id,
              unit: item.unit,
              title: item.title,
              referenceRange: {
                top: item.referenceRange.top.toString() || "",
                bottom: item.referenceRange.bottom.toString() || "",
              },
              optimalRange: {
                top: item.optimalRange?.top.toString() || "",
                bottom: item.optimalRange?.bottom.toString() || "",
              },
              archived: item.archived,
            };
          }),
        };
      }),
    });
  };

  useEffect(() => {
    if (!editingId) return;

    getDoc(doc(labsCollection, editingId))
      .then((snap) => {
        const lab = snap.data();
        if (lab) {
          setLabFromCopy(lab);
        } else {
          setNotFound(true);
        }
      })
      .finally(() => {
        // TODO: Either implement this or delete it
        // setLoadingCopy(false);
      });
  }, [editingId]);

  if (notFound) {
    return <NotFound />;
  }

  const saveLab = async () => {
    setSaving(true);
    try {
      const newLab: Lab = {
        archived: lab.archived || false,
        title: lab.title,
        panels: lab.panels.map((panelInput) => {
          const panel: Panel = {
            id: panelInput.id,
            title: panelInput.title,
            items: panelInput.items.map((itemInput) => {
              const item: PanelItem = {
                id: itemInput.id,
                title: itemInput.title,
                unit: itemInput.unit,
                referenceRange: {
                  bottom: itemInput.referenceRange.bottom,
                  top: itemInput.referenceRange.top,
                },
              };
              if (itemInput.optimalRange.bottom && itemInput.optimalRange.top) {
                item.optimalRange = {
                  bottom: itemInput.optimalRange.bottom,
                  top: itemInput.optimalRange.top,
                };
              }
              return item;
            }),
          };

          return panel;
        }),
      };

      if (editingId) {
        await updateDoc(doc(labsCollection, editingId), newLab);
      } else {
        labs?.forEach((labTemplate) => {
          if (
            labTemplate.data().title.toLocaleLowerCase() ===
            newLab.title.toLocaleLowerCase()
          ) {
            throw new Error("Lab name already exists!");
          }
        });

        await addDoc(labsCollection, newLab);
        setLab({
          panels: [],
          title: "",
        });
      }
      toast({ status: "success", description: "Lab saved!" });
    } catch (e) {
      handleError(e);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Stack spacing={5}>
      <AdminBloodWork />
      <Heading>{editingId ? "Edit" : "Create"} Lab</Heading>
      <Divider />
      <Box>
        <Button
          size="lg"
          isDisabled={saving}
          isLoading={saving}
          onClick={saveLab}
        >
          Save
        </Button>
      </Box>
      {!editingId && (
        <>
          <Stack spacing={2}>
            <FormControl maxW="md">
              <FormLabel>Copy Existing Lab</FormLabel>
              <Select
                maxW="md"
                placeholder="Choose Lab to Copy"
                value={copyLabId}
                onChange={(e) => {
                  setCopyLabId(e.target.value);
                }}
              >
                {labs &&
                  labs.map((doc) => {
                    return (
                      <option value={doc.id} key={doc.id}>
                        {doc.data().title}
                      </option>
                    );
                  })}
              </Select>
            </FormControl>
            {labs && (
              <Box>
                <Button
                  colorScheme="gray"
                  leftIcon={<CopyIcon />}
                  isDisabled={!copyLabId}
                  onClick={() => {
                    const copyLab = labs
                      .find((doc) => doc.id === copyLabId)
                      ?.data();
                    if (
                      copyLab &&
                      window.confirm(
                        `Warning: This will overwrite this entire form and replace it with the entirety of lab "${copyLab.title}". Proceed?`
                      )
                    ) {
                      setCopyLabId("");
                      setLabFromCopy(copyLab);
                    }
                  }}
                >
                  Copy
                </Button>
              </Box>
            )}
          </Stack>
          <Divider />
        </>
      )}

      <Stack spacing={3}>
        <FormControl maxW="md">
          <FormLabel>Lab Title</FormLabel>
          <Input
            placeholder="e.g. Quest, LabCorp, etc..."
            value={lab.title}
            onChange={(e) => {
              setLab({
                ...lab,
                title: e.target.value,
              });
            }}
          />
        </FormControl>
        <HStack spacing={2}>
          <Button
            size="sm"
            variant="outline"
            leftIcon={<AddIcon />}
            onClick={() => {
              setLab({
                ...lab,
                panels: [
                  ...lab.panels,
                  {
                    id: randomId(),
                    title: "",
                    items: [],
                  },
                ],
              });
            }}
          >
            Add Panel
          </Button>
          <Button
            size="sm"
            variant="outline"
            colorScheme={lab.archived ? "gray" : "red"}
            leftIcon={lab.archived ? <FaEye /> : <FaEyeSlash />}
            onClick={() => {
              setLab({
                ...lab,
                archived: !lab.archived,
              });
            }}
          >
            {lab.archived ? "Unarchive Lab" : "Archive Lab"}
          </Button>
        </HStack>
      </Stack>
      {lab.panels.map((panel) => {
        return (
          <Fragment key={panel.id}>
            <Divider />
            <Stack spacing={3}>
              <FormControl maxW="md">
                <FormLabel>Panel Title</FormLabel>
                <Input
                  placeholder="e.g. Comprehensive Metabolic Panel, Complete Blood Count, etc..."
                  value={panel.title}
                  onChange={(e) => {
                    setLab({
                      ...lab,
                      panels: lab.panels.map((p) => {
                        if (p.id !== panel.id) return p;
                        return {
                          ...p,
                          title: e.target.value,
                        };
                      }),
                    });
                  }}
                />
              </FormControl>
              <HStack spacing={2}>
                <Button
                  size="sm"
                  variant="outline"
                  leftIcon={<AddIcon />}
                  onClick={() => {
                    setLab({
                      ...lab,
                      panels: lab.panels.map((p) => {
                        if (p.id !== panel.id) return p;
                        return {
                          ...p,
                          items: [
                            ...p.items,
                            {
                              id: randomId(),
                              referenceRange: {
                                bottom: "",
                                top: "",
                              },
                              optimalRange: {
                                bottom: "",
                                top: "",
                              },
                              title: "",
                              unit: "",
                              archived: false,
                            },
                          ],
                        };
                      }),
                    });
                  }}
                >
                  Add Panel Item
                </Button>
                <Button
                  size="sm"
                  colorScheme={panel.archived ? "gray" : "red"}
                  variant="outline"
                  leftIcon={panel.archived ? <FaEye /> : <FaEyeSlash />}
                  onClick={() => {
                    setLab({
                      ...lab,
                      panels: lab.panels.map((p) => {
                        if (panel.id === p.id) {
                          return {
                            ...p,
                            archived: !panel.archived,
                          };
                        } else return p;
                      }),
                    });
                  }}
                >
                  {panel.archived ? "Unarchive Panel" : "Archive Panel"}
                </Button>
              </HStack>
              <TableContainer>
                <Table size="sm" variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Unit</Th>
                      <Th>Ref. Rng.</Th>
                      <Th>Opt. Rng.</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {panel.items.map((item, index) => {
                      const update = (partial: Partial<PanelItemInput>) => {
                        setLab({
                          ...lab,
                          panels: lab.panels.map((p) => {
                            if (p.id !== panel.id) return p;
                            const copy = [...p.items];
                            copy[index] = {
                              ...p.items[index],
                              ...partial,
                            };
                            return {
                              ...p,
                              items: copy,
                            };
                          }),
                        });
                      };
                      return (
                        <Tr key={item.id}>
                          <Td>
                            <Input
                              size="sm"
                              placeholder="e.g. Sodium, Potassium, etc..."
                              value={item.title}
                              onChange={(e) => {
                                update({ title: e.target.value });
                              }}
                            />
                          </Td>
                          <Td>
                            <Select
                              size="sm"
                              value={item.unit}
                              placeholder="Unit"
                              onChange={(e) => {
                                const { value } = e.target;
                                update({
                                  unit: value,
                                });
                              }}
                            >
                              {chain(bloodWorkUnits)
                                .map((text, unit) => {
                                  const textLowerCase = text.toLowerCase();
                                  return {
                                    text,
                                    unit,
                                    textLowerCase,
                                  };
                                })
                                .orderBy("textLowerCase", "asc")
                                .map(({ text, unit }) => {
                                  return (
                                    <option value={unit} key={unit}>
                                      {text}
                                    </option>
                                  );
                                })
                                .value()}
                            </Select>
                          </Td>
                          <Td>
                            <Input
                              size="sm"
                              value={item.referenceRange.bottom}
                              maxW="60px"
                              onChange={(e) => {
                                update({
                                  referenceRange: {
                                    ...item.referenceRange,
                                    bottom: e.target.value,
                                  },
                                });
                              }}
                            />
                            {" - "}
                            <Input
                              size="sm"
                              maxW="60px"
                              value={item.referenceRange.top}
                              onChange={(e) => {
                                update({
                                  referenceRange: {
                                    ...item.referenceRange,
                                    top: e.target.value,
                                  },
                                });
                              }}
                            />
                          </Td>
                          <Td>
                            <Input
                              size="sm"
                              value={item.optimalRange.bottom}
                              maxW="60px"
                              onChange={(e) => {
                                update({
                                  optimalRange: {
                                    ...item.optimalRange,
                                    bottom: e.target.value,
                                  },
                                });
                              }}
                            />
                            {" - "}
                            <Input
                              size="sm"
                              value={item.optimalRange.top}
                              maxW="60px"
                              onChange={(e) => {
                                update({
                                  optimalRange: {
                                    ...item.optimalRange,
                                    top: e.target.value,
                                  },
                                });
                              }}
                            />
                          </Td>
                          <Td>
                            <Box>
                              <Button
                                variant="link"
                                colorScheme={item.archived ? undefined : "red"}
                                onClick={() => {
                                  update({ archived: !item.archived });
                                }}
                                size="sm"
                              >
                                {item.archived ? "Unarchive" : "Archive"}
                              </Button>
                            </Box>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
          </Fragment>
        );
      })}
      <Box>
        <Button
          size="lg"
          isDisabled={saving}
          isLoading={saving}
          onClick={saveLab}
        >
          Save
        </Button>
      </Box>
    </Stack>
  );
};
