import React, { useRef, useState, useEffect } from "react";
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  Input,
  Link,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadString } from "firebase/storage";
import ReactSignatureCanvas from "react-signature-canvas";
import parse from "html-react-parser";

import AuthHeader from "../components/AuthHeader";
import useHandleError from "../hooks/useHandleError";
import { contactEmail } from "../utils/contactEmail";
import "react-quill/dist/quill.snow.css";

type ContentBlock = {
  id: string;
  text: string;
};

export default function SignUpPage() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [esign, setEsign] = useState("");
  const [termsContent, setTermsContent] = useState<string | null>(null);

  const sigRef = useRef<ReactSignatureCanvas | null>(null);
  const toast = useToast();
  const navigate = useNavigate();
  const handleError = useHandleError();

  // 1. Fetch Terms of Service content FIRST.
  //    We do NOT render the sign-up form until this completes.
  useEffect(() => {
    const fetchTermsContent = async () => {
      try {
        const db = getFirestore();
        const docRef = doc(db, "content", "termsOfService");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const contentBlocks: ContentBlock[] =
            docSnap.data().contentBlocks || [];
          const termsBlock = contentBlocks.find(
            (block) => block.id === "Terms of Service"
          );
          if (termsBlock) {
            setTermsContent(termsBlock.text);
          } else {
            // If the document exists but no "Terms of Service" block is found
            setTermsContent("");
          }
        } else {
          // If the doc does not exist
          setTermsContent("");
        }
      } catch (error) {
        console.error("Error fetching terms of service:", error);
        setTermsContent("");
      }
    };

    fetchTermsContent();
  }, []);

  // 2. Show a loading spinner or placeholder until termsContent is loaded
  if (termsContent === null) {
    return (
      <Container>
        <Stack spacing={4} py={8} align="center">
          <AuthHeader />
          <Text>Loading Content...</Text>
          <Spinner />
        </Stack>
      </Container>
    );
  }

  // 3. Once we have the TOS content (even if empty), render the sign-up form
  const signUp = async () => {
    if (password !== confirmPassword) {
      return toast({
        status: "warning",
        title: "Passwords do not match",
        description: "Password fields should match.",
        isClosable: true,
      });
    }

    if (!checked) {
      return toast({
        status: "warning",
        title: "Terms of Service",
        description: "Please check the box to accept our Terms of Service.",
        isClosable: true,
      });
    }

    const signature = sigRef.current?.toDataURL();
    if (!esign) {
      if (!signature || sigRef.current?.isEmpty()) {
        return toast({
          status: "warning",
          title: "Signature Required",
          description:
            "Please sign to confirm that you agree to the Terms of Service.",
          isClosable: true,
        });
      }
    }

    setLoading(true);

    try {
      // Create the user in Firebase Auth
      const {
        user: { uid },
      } = await createUserWithEmailAndPassword(getAuth(), email, password);

      // Navigate away after successful sign-up
      navigate("/");

      // If we have a signature, upload it to Firebase Storage
      if (signature) {
        await uploadString(
          ref(getStorage(), `users/${uid}/signature.png`),
          signature.replace("data:image/png;base64,", ""),
          "base64",
          { contentType: "image/png" }
        );
      }
    } catch (e) {
      setLoading(false);
      handleError(e);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        signUp();
      }}
    >
      <Container>
        <Stack spacing={4}>
          <AuthHeader />
          <Text textAlign="center" fontSize="medium">
            Have an account?{" "}
            <Link as={RouterLink} to="/sign-in" color="purple.600">
              Sign In
            </Link>
          </Text>

          <Alert
            borderRadius="md"
            variant="subtle"
            backgroundColor="purple.100"
          >
            <AlertDescription>
              This Registry is for those diagnosed with ALS or an ALS-related
              disorder. Please put in the PALS name and signature, not the CALS.
              Medical professionals or researchers may also register to see what
              info is being asked of their ALS patients.
            </AlertDescription>
          </Alert>

          {/* Email & Password fields */}
          <Stack spacing={2}>
            <Input
              placeholder="Email"
              type="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              placeholder="Password"
              type="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              placeholder="Confirm Password"
              type="password"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Stack>

          {/* Terms of Service Content (now only rendered once loaded) */}
          <Stack spacing={2}>
            <Text textStyle="3x1" fontWeight="bold">
              Terms of Service
            </Text>
            <Box className="ql-container ql-snow" maxH="400px" overflowY="auto">
              <Box className="ql-editor">{parse(termsContent)}</Box>
            </Box>
          </Stack>

          {/* User must accept TOS */}
          <Stack spacing={2}>
            <Checkbox
              isChecked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            >
              I have read and agree to the Terms of Service above.
            </Checkbox>

            <Text>Please sign:</Text>
            <Stack spacing={1}>
              <Stack
                borderWidth={1}
                borderColor="gray.600"
                borderStyle="dashed"
                rounded="md"
              >
                <ReactSignatureCanvas
                  ref={sigRef}
                  penColor="black"
                  canvasProps={{ height: 200 }}
                />
              </Stack>
              <Flex justify="end">
                <Button
                  size="xs"
                  onClick={() => {
                    sigRef.current?.clear();
                  }}
                  colorScheme="gray"
                  variant="ghost"
                >
                  Clear
                </Button>
              </Flex>
            </Stack>

            <Stack direction="row">
              <Text fontWeight="bold">OR</Text>
              <Text>
                I agree that my typed name below serves as my electronic
                signature
              </Text>
            </Stack>
            <Input
              placeholder="E-Signature (Full-name)"
              value={esign}
              onChange={(e) => setEsign(e.target.value)}
            />
          </Stack>

          {/* Submit */}
          <Button mt={3} type="submit" w="full" isLoading={loading}>
            Sign Up
          </Button>

          <Text fontSize="medium">
            Have an account?{" "}
            <Link as={RouterLink} to="/sign-in" color="purple.600">
              Sign In
            </Link>
          </Text>
          <Text>
            For questions about the registry, please email us at:{" "}
            <a href={`mailto:${contactEmail}`}>{contactEmail}</a>.
          </Text>
        </Stack>
      </Container>
    </form>
  );
}
