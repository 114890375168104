import { Collection } from '../../types/contentManagement'

export const collections: Collection[] = [
  {
    name: 'Purple Bubbles',
    docName: 'purpleBubbles',
    description:
      'Each page in the application is able to have a purple bubble which provides important information to the PALs and CALs',
  },
  {
    name: 'Help',
    docName: 'help',
    description: 'Frequency asked questions content',
  },
  {
    name: 'Terms of Service',
    docName: 'termsOfService',
    description: 'Terms of service on sign-up page',
  },
]
