import { AddIcon, QuestionIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  StackDivider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { chain, keys, map } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useUnarchivedDoctors } from '../hooks/useDoctors'
import { useCurrentOrPreviousReportsArray } from '../hooks/useReports'
import useUpdateReportDoc from '../hooks/useUpdateReportDoc'
import { MedicalSupportMonth } from '../types/support'
import { contactEmail } from '../utils/contactEmail'
import { useReportingForParam } from '../hooks/useReportingForMonth'

export default () => {
  const [editingId, setEditingId] = useState<string>()
  const addDialog = useDisclosure()
  const [search, setSearch] = useState('')
  const currentOrPastReports = useCurrentOrPreviousReportsArray()
  const reportingFor = useReportingForParam()

  const monthInput = useMemo(() => {
    if (currentOrPastReports[0]?.medicalSupport) {
      return {
        medicalSupport: currentOrPastReports[0]?.medicalSupport,
        reportingMonth: currentOrPastReports[0]?.id,
      }
    } else if (currentOrPastReports[1]?.medicalSupport) {
      return {
        medicalSupport: currentOrPastReports[1]?.medicalSupport,
        reportingMonth: currentOrPastReports[1]?.id,
      }
    } else {
      return { medicalSupport: {}, reportingMonth: null }
    }
  }, [currentOrPastReports])

  const updateReport = useUpdateReportDoc()
  const setMonthInput = useCallback(
    async (medicalSupport: MedicalSupportMonth) => {
      updateReport({ medicalSupport })
    },
    [updateReport]
  )

  const doctorIds = useMemo(() => {
    const medicalSupport = monthInput.medicalSupport as MedicalSupportMonth
    return Object.keys(medicalSupport).filter((id) => !!medicalSupport[id])
  }, [monthInput.medicalSupport])

  const doctors = useUnarchivedDoctors()
  const [virtual, setVirtual] = useState('0')
  const [inPerson, setInPerson] = useState('0')
  const [didCommunicate, setDidCommunicate] = useState<boolean | null>(false)
  const [communications, setCommunications] = useState('0')
  const toast = useToast()

  const editingDoctor = doctors.find((d) => d.id === editingId)?.data()

  const searchDoctors = !search
    ? []
    : doctors.filter((d) => {
        const doctor = d.data()

        return chain(doctor.firstName + doctor.lastName)
          .replace(' ', '')
          .toLower()
          .value()
          .includes(chain(search).toLower().replace(' ', '').value())
      })

  const closeEditingDialog = () => {
    setVirtual('0')
    setInPerson('0')
    setDidCommunicate(false)
    setCommunications('0')
    setEditingId(undefined)
  }

  return (
    <Stack spacing={6}>
      <Stack>
        <HStack>
          <Text fontSize="large" fontWeight="bold">
            Part 2:{' '}
          </Text>
          <Text fontSize="large">Medical Professionals</Text>
        </HStack>
        <Text>
          How many hours did you spend with each member of your Medical Team
          this month (doctors, naturopaths, nutritionists, health coaches)?
        </Text>
        <Text fontSize="sm">
          See Physical Strategies for respiratory, physical and speech therapy,
          acupuncture. Chiropractic is under physical strategies except when
          giving health coaching.
        </Text>
        <Box>
          <Button leftIcon={<AddIcon />} onClick={addDialog.onOpen} size="sm">
            Add
          </Button>
        </Box>
      </Stack>
      {doctorIds.length > 0 && (
        <MedicalSupportTable
          reportingFor={reportingFor}
          reportingMonth={monthInput.reportingMonth}
          medicalSupport={monthInput.medicalSupport as MedicalSupportMonth}
          onEdit={(doctorId) => {
            setEditingId(doctorId)
            const doctorInput = (
              monthInput.medicalSupport as MedicalSupportMonth
            )[doctorId]

            setInPerson(doctorInput?.inPerson.toString() || '')
            setVirtual(doctorInput?.virtual.toString() || '')
            setCommunications(doctorInput?.communications.toString() || '')
            setDidCommunicate(!!doctorInput?.communications)
          }}
          onRemove={(doctorId) => {
            if (window.confirm('Remove this practitioner from this report?')) {
              const copy = { ...monthInput }
              copy.medicalSupport = {
                ...(copy.medicalSupport as MedicalSupportMonth),
              }
              delete (copy.medicalSupport as MedicalSupportMonth)[doctorId]
              setMonthInput(copy.medicalSupport as MedicalSupportMonth)
            }
          }}
        />
      )}
      <Modal isOpen={addDialog.isOpen} onClose={addDialog.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={5}>
            <Stack spacing={4}>
              <Input
                placeholder="Start typing to find your doctor..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {searchDoctors.length > 0 ? (
                <Stack divider={<StackDivider />} spacing={0} cursor="pointer">
                  {searchDoctors.map((d) => {
                    const doctor = d.data()
                    return (
                      <Stack
                        key={d.id}
                        spacing={0}
                        _hover={{
                          backgroundColor: 'gray.100',
                        }}
                        py={2}
                        onClick={() => {
                          addDialog.onClose()
                          if (!doctorIds.includes(d.id)) {
                            setEditingId(d.id)
                          }
                        }}
                      >
                        <Text fontWeight="bold">
                          {doctor.firstName} {doctor.lastName},{' '}
                          {doctor.letters.join('/')}
                        </Text>
                        <Text fontSize="sm">
                          {doctor.city}, {doctor.state}, {doctor.country}
                        </Text>
                      </Stack>
                    )
                  })}
                </Stack>
              ) : (
                <Stack>
                  <Text>Can't find your doctor in our search?</Text>
                  <Button
                    onClick={() => {
                      addDialog.onClose()
                      toast({
                        status: 'info',
                        title: 'Add Doctor',
                        description: `Please email us at: ${contactEmail} and we'll be happy to add your doctors to this list.`,
                      })
                    }}
                  >
                    Email Us
                  </Button>
                </Stack>
              )}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={!!editingId} onClose={closeEditingDialog}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editingDoctor
              ? `${editingDoctor.firstName} ${editingDoctor.lastName}`
              : '?'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              <FormControl isRequired>
                <FormLabel>
                  In-Person Hours This Month <HoursPopover />
                </FormLabel>
                <HoursSelect value={inPerson} onChange={setInPerson} />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>
                  Virtual Hours This Month <HoursPopover />
                </FormLabel>
                <HoursSelect value={virtual} onChange={setVirtual} />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>
                  Did you receive email or text support this month from this
                  practitioner?
                </FormLabel>
                <RadioGroup
                  onChange={(value) => {
                    setDidCommunicate(value === 'yes')
                    setCommunications('0')
                  }}
                  value={
                    didCommunicate === null
                      ? undefined
                      : didCommunicate
                      ? 'yes'
                      : 'no'
                  }
                >
                  <Stack spacing={1}>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl
                isRequired={!!didCommunicate}
                isDisabled={!didCommunicate}
              >
                <FormLabel>
                  If yes, number of texts or emails this month:
                </FormLabel>
                <HoursSelect
                  value={communications}
                  onChange={setCommunications}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              colorScheme="gray"
              onClick={closeEditingDialog}
              mr={3}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              onClick={() => {
                if (!editingId) return

                const _inPerson = parseFloat(inPerson)
                const _virtual = parseFloat(virtual)
                const _communications = parseFloat(communications)

                if (
                  isNaN(_inPerson) ||
                  isNaN(_virtual) ||
                  isNaN(_communications) ||
                  (didCommunicate && _communications < 1)
                ) {
                  return toast({
                    status: 'warning',
                    title: 'Field Missing',
                    description: 'Please fill out all required fields.',
                    isClosable: true,
                  })
                }

                setMonthInput({
                  ...(monthInput.medicalSupport as MedicalSupportMonth),
                  [editingId]: {
                    inPerson: _inPerson,
                    virtual: _virtual,
                    communications: _communications,
                  },
                })

                closeEditingDialog()
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  )
}

const HoursSelect = ({
  value,
  onChange,
}: {
  value?: string
  onChange: (value: string) => void
}) => {
  return (
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      type="number"
    />
  )
}

const HoursPopover = () => {
  return (
    <Popover>
      <PopoverTrigger>
        <QuestionIcon color="gray.400" />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          If you are going to an institute or medical office for care put the
          total number of hours spent in overall care and list the supervising
          doctor in charge.
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export const MedicalSupportTable = ({
  medicalSupport,
  onEdit,
  onRemove,
  reportingFor,
  reportingMonth,
}: {
  medicalSupport: MedicalSupportMonth
  onEdit?: (doctorId: string) => void
  onRemove?: (doctorId: string) => void
  reportingFor: string
  reportingMonth: string | null
}) => {
  const doctors = useUnarchivedDoctors()
  if (!doctors) return null

  return (
    <TableContainer>
      <Table size="sm">
        <Thead>
          <Tr>
            {onEdit && <Th />}
            {onRemove && <Th />}
            <Th>Name</Th>
            <Th>City</Th>
            <Th>In-Person Hrs.</Th>
            <Th>Virtual Hrs.</Th>
            <Th># Communications</Th>
          </Tr>
        </Thead>
        <Tbody>
          {map(medicalSupport, (doctorInput, doctorId) => {
            if (!doctorInput) {
              doctorInput = {
                communications: 0,
                inPerson: 0,
                virtual: 0,
              }
            }
            const doc = doctors.find((d) => d.id === doctorId)
            const doctor = doc?.data()
            const displayValues = reportingMonth === reportingFor

            return (
              <Tr key={doctorId}>
                {onEdit && (
                  <Td>
                    <Button
                      size="xs"
                      variant="link"
                      onClick={() => onEdit(doctorId)}
                    >
                      Edit Hours
                    </Button>
                  </Td>
                )}
                {onRemove && (
                  <Td>
                    <Button
                      size="xs"
                      variant="link"
                      colorScheme="red"
                      onClick={() => onRemove(doctorId)}
                    >
                      Remove
                    </Button>
                  </Td>
                )}
                <Td>
                  {doctor ? `${doctor.firstName} ${doctor.lastName}` : '?'}
                </Td>
                <Td>{doctor?.city || '?'}</Td>
                <Td>{displayValues ? doctorInput.inPerson : ''}</Td>
                <Td>{displayValues ? doctorInput.virtual : ''}</Td>
                <Td>{displayValues ? doctorInput.communications : ''}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
