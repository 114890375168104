import { Text, Tr, Td, Checkbox, Input } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { ExposureData } from '../../types/FirestoreUser'

interface ExposureRowProps {
  condition: string
  uid: string
  exposureHistory: ExposureData[]
  setExposureHistory: (data: ExposureData[]) => void
  userAge: number | undefined
}

export default function ExposureRow({
  condition,
  uid,
  exposureHistory,
  setExposureHistory,
  userAge,
}: ExposureRowProps) {
  const [isChecked, setIsChecked] = useState(false)
  const [ageDiagnosed, setAgeDiagnosed] = useState<number | undefined>()
  const [ageEnded, setAgeEnded] = useState<number | undefined>()
  const [estimatedYears, setEstimatedYears] = useState<number | null>(null)

  useEffect(() => {
    const existingCondition = exposureHistory.find(
      (item) => item.condition === condition
    )
    if (existingCondition) {
      setIsChecked(true)
      setAgeDiagnosed(existingCondition.ageDiagnosed ?? undefined)
      setAgeEnded(existingCondition.ageEnded ?? undefined)
      setEstimatedYears(existingCondition.estimatedYears ?? null)
    } else {
      setIsChecked(false)
      setAgeDiagnosed(undefined)
      setAgeEnded(undefined)
      setEstimatedYears(null)
    }
  }, [condition, exposureHistory])

  useEffect(() => {
    const updatedHistory = exposureHistory.filter(
      (item) => item.condition !== condition
    )

    if (isChecked) {
      updatedHistory.push({
        id: condition,
        condition,
        ageDiagnosed: ageDiagnosed ?? null,
        ageEnded: ageEnded ?? null,
        estimatedYears: estimatedYears ?? null,
      })
    }

    setExposureHistory(updatedHistory)
  }, [
    isChecked,
    ageDiagnosed,
    ageEnded,
    estimatedYears,
    exposureHistory,
    setExposureHistory,
    condition,
  ])

  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const checked = e.target.checked
    setIsChecked(checked)
    if (!checked) {
      setAgeDiagnosed(undefined)
      setAgeEnded(undefined)
      setEstimatedYears(null)
    }
  }

  function handleAgeDiagnosedChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    const ageDiagnosedValue = value === '' ? undefined : Number(value)
    setAgeDiagnosed(ageDiagnosedValue)
    calculateEstimatedYears(ageDiagnosedValue, ageEnded)
  }

  function handleAgeEndedChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    const ageEndedValue = value === '' ? undefined : Number(value)
    setAgeEnded(ageEndedValue)
    calculateEstimatedYears(ageDiagnosed, ageEndedValue)
  }

  function handleEstimatedYearsChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    const estimatedYearsValue = value === '' ? null : Number(value)
    setEstimatedYears(estimatedYearsValue)
  }

  function calculateEstimatedYears(
    ageDiagnosed: number | undefined,
    ageEnded: number | undefined
  ) {
    if (ageDiagnosed !== undefined) {
      if (ageEnded !== undefined) {
        setEstimatedYears(ageEnded - ageDiagnosed)
      } else if (userAge !== undefined) {
        setEstimatedYears(userAge - ageDiagnosed)
      } else {
        setEstimatedYears(null)
      }
    } else {
      setEstimatedYears(null)
    }
  }

  return (
    <Tr>
      <Td p={2}>
        <Text fontSize="sm">{condition}</Text>
      </Td>
      <Td textAlign="left" p={2}>
        <Checkbox
          name="condition"
          isChecked={isChecked}
          onChange={handleCheckboxChange}
          ml={4}
        />
      </Td>
      <Td p={2}>
        <Input
          size="sm"
          type="number"
          name="ageDiagnosed"
          placeholder="Age Started"
          isDisabled={!isChecked}
          isRequired={isChecked}
          value={ageDiagnosed ?? ''}
          onChange={handleAgeDiagnosedChange}
        />
      </Td>
      <Td p={2}>
        <Input
          size="sm"
          type="number"
          name="ageEnded"
          placeholder="Age Ended"
          isDisabled={!isChecked}
          value={ageEnded ?? ''}
          onChange={handleAgeEndedChange}
        />
      </Td>
      <Td p={2}>
        <Input
          size="sm"
          type="number"
          name="estimatedYears"
          placeholder="Estimated Years"
          isDisabled={!isChecked}
          isInvalid={estimatedYears !== null && estimatedYears < 0}
          value={estimatedYears ?? ''}
          onChange={handleEstimatedYearsChange}
        />
      </Td>
    </Tr>
  )
}
