export const moldConditions = [
  { name: 'Known mold or mycotoxin exposure at home' },
  { name: 'Known mold or mycotoxin exposure at work' },
]

export const heavyMetalConditions = [
  { name: 'Arsenic' },
  { name: 'Asbestos' },
  { name: 'Aluminum: pots and pans' },
  { name: 'Aluminum: Lived near aluminum plant' },
  { name: 'Aluminum: Personal products (deodorant)' },
  { name: 'Aluminum: Food containing aluminum, antacid consumption' },
  { name: 'Aluminum: Other exposure' },
  { name: 'Cadmium' },
  { name: 'Lead: Welder exposed to lead at work' },
  { name: 'Lead: Artist worked with lead paints or stained glass' },
  { name: 'Lead: Painted inside or outside with lead paint' },
  { name: 'Lead: Exposed to lead pipes or lead paint at home' },
  { name: 'Lead: Foods or water containing lead' },
  { name: 'Lead: Other known lead exposure' },
  { name: 'Mercury: Dental amalgams' },
  { name: 'Mercury: Live near coal plant' },
  { name: 'Mercury: Large fish consumption (tuna, swordfish, etc.)' },
  { name: 'Mercury: Worked in dental office' },
  { name: 'Mercury: Other non-dental mercury exposure' },
]

export const petroleumConditions = [
  { name: 'Live near a gas station' },
  { name: 'Worked at a gas station' },
  { name: 'Live in a city with heavy traffic or live near a major highway' },
  { name: 'Other known petroleum exposure' },
]

export const pesticideConditions = [
  { name: 'Lived on or near a conventional farm' },
  { name: 'Worked on a conventional farm' },
  { name: 'Lived near a golf course' },
  { name: 'Foods containing DDT' },
  { name: 'Foods containing Glyphosate' },
  { name: 'Foods containing Atrazine' },
  { name: 'Foods containing Chlorpyrifos' },
  { name: 'Foods containing other pesticides' },
]

export const chemicalConditions = [
  { name: 'Formaldehyde' },
  {
    name: 'Paints and stains including artists, painters, carpenters, hobbies',
  },
  { name: 'Solvents' },
  { name: 'Toxins in skin care, makeup, personal care products' },
  { name: 'Beautician exposed to hair dyes and related chemicals' },
  { name: 'Tattoos' },
  { name: 'Bleach' },
  { name: 'Toxic cleaning products' },
  { name: 'Cement (near a plant, worked with cement)' },
  { name: 'Flame retardants on clothing, furniture, others' },
  {
    name: 'PFOS exposure in non-stick cookware, waterproof clothing, furniture',
  },
  { name: 'Worked as firefighter' },
  { name: 'Woodworking, working with wood, solvents, stains' },
]

export const plasticConditions = [
  { name: 'Bottled water BPA, Phthalates, nanoplastics' },
  { name: 'Plastic food containers' },
]

export const militaryConditions = [
  { name: 'Lived on military base' },
  { name: 'Exposed to chemicals, artillery, explosives in military' },
]

export const geopathicConditions = [
  { name: 'Geopathic fields slept on or spent time on' },
]

export const emfConditions = [
  { name: 'Bed near electricity coming into home' },
  { name: 'Live near cell tower' },
  { name: 'Live near electrical relay station or electrical tower' },
  { name: 'Work near internet router' },
]

export const radiationConditions = [
  { name: 'Radon' },
  { name: 'Lived near nuclear power plant' },
  { name: 'Other' },
]

export const carbonMonoxideConditions = [
  { name: 'Exposure to carbon monoxide' },
]
