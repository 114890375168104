import { Progress, Stack, Text, Textarea } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import MonthlyReportHeader from "../components/MonthlyReportHeader";
import MonthlyReportNavButtons from "../components/MonthlyReportNavButtons";
import useCurrentReport from "../hooks/useCurrentReport";
import useNavigateNext from "../hooks/useNavigateNext";
import useUpdateReportDoc from "../hooks/useUpdateReportDoc";
import usePurpleBubblesText from "../hooks/usePurpleBubblesText";
import PurpleBubbleContent from "../components/PurpleBubbleContent";
import useHandleError from "../hooks/useHandleError";

const journalTemplate = "Week 1:\n\nWeek 2:\n\nWeek 3:\n\nWeek 4:\n\nWeek 5:";
const minCharacters = 20;

export default () => {
  const [clinicalObservations, setClinicalObservations] =
    useState(journalTemplate);
  const updateReport = useUpdateReportDoc();
  const navNext = useNavigateNext();
  const report = useCurrentReport();
  const purpleBubbleContent = usePurpleBubblesText("Step-6");
  const handleError = useHandleError();
  const isValid =
    clinicalObservations.length > journalTemplate.length + minCharacters;
  const charactersNeededPercentage = () => {
    if (isValid) {
      return 100;
    }
    return (
      ((clinicalObservations.length - journalTemplate.length) / minCharacters) *
      100
    );
  };
  const progressColor = () => {
    if (isValid) {
      return "green";
    } else if (charactersNeededPercentage() > 70) {
      return "cyan";
    } else if (charactersNeededPercentage() > 20) {
      return "yellow";
    }
    return "pink";
  };

  useEffect(() => {
    if (typeof report?.clinicalObservations === "string") {
      setClinicalObservations(report.clinicalObservations);
    }
  }, [report]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (isValid) {
          updateReport({ clinicalObservations });
          navNext();
        } else {
          handleError("You must write a minimum of one sentence per month.");
        }
      }}
    >
      <Stack spacing={4}>
        <MonthlyReportHeader title="Journal - Clinical Observations" />
        {purpleBubbleContent?.text && (
          <PurpleBubbleContent text={purpleBubbleContent.text} />
        )}
        <Text>
          Please note any physical or mental changes during this month, positive
          or negative. We suggest one entry per week if you are starting a new
          protocol or supplements.
        </Text>
        <Text>
          You must write a minimum of one sentence per month. (20 characters)
        </Text>
        <Progress
          value={charactersNeededPercentage()}
          size="xs"
          colorScheme={progressColor()}
        />
        <Textarea
          value={clinicalObservations}
          onChange={(e) => setClinicalObservations(e.target.value)}
          height="sm"
        />
        <MonthlyReportNavButtons />
      </Stack>
    </form>
  );
};
